<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_purchase_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :placeholder="$t('supplier_purchase_report.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_purchase_report.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('supplier_purchase_report.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('order_by') }}</label>
                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option value="asc">{{ $t('asc') }}</option>
                  <option value="desc">{{ $t('desc') }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_purchase_report.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('supplier_purchase_report.from_date') }}</label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('supplier_purchase_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>



              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <!--begin::customer-->
    <div class="card card-custom mt-5">

      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab active :title="$t('details')">
            <div class="col-md-12 mb-5">
              <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                  <div class="col-12 mt-10" :id="'summaryTableDetailsTitle'">
                    <!-- Clients Installments - Group By Client -->
                    <h4 class="text-center">{{ $t('supplier_purchase_report.supplier_purchase_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                                    {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                        <span><i class="fa fa-file-excel"></i> {{ $t('excel') }}</span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTableDetails')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <!--              <div class="col-12 mt-10" v-if="dataChartList">-->
                  <!--                <pie-chart :data-list="dataChartList"></pie-chart>-->
                  <!--              </div>-->
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" :id="'summaryTableDetails'">
                        <thead>
                        <tr id="myRow">
                          <th>{{ $t('supplier_purchase_report.date') }}</th>
                          <th>{{ $t('supplier_purchase_report.type') }}</th>
                          <th>{{ $t('supplier_balance_report.code') }}</th>
                          <th>{{ $t('supplier_purchase_report.supplier') }}</th>
                          <th>{{ $t('supplier_purchase_report.branch') }}</th>
                          <th>{{ $t('supplier_purchase_report.value') }}</th>
                          <th>{{ $t('supplier_purchase_report.discounts') }}</th>
                          <th>{{ $t('supplier_purchase_report.taxes') }}</th>
                          <th>{{ $t('supplier_purchase_report.total_total') }}</th>
                          <th>{{ $t('customer_installment_report.remaining_amount') }}</th>
                        </tr>
                        </thead>
                        <tbody v-if="filters.group_by == 'invoice'">
                        <tr v-for="(row2, index2) in data" :key="'c'+getRandom()+index2">
                          <td>{{ row2.date }}</td>
                          <td>{{ row2.type }}</td>
                          <td>{{ row2.code }}</td>
                          <td>{{ row2.name }}</td>
                          <td>{{ row2.branch }}</td>
                          <td>{{ row2.value }}</td>
                          <td>{{ row2.discount }}</td>
                          <td>{{ row2.taxes }}</td>
                          <td>{{ row2.total }}</td>
                          <td>{{ row2.remaining_amount }}</td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <template v-for="(row, index) in data">
                          <tr :key="'c'+index">
                            <td colspan="10">{{ row.label }}</td>
                          </tr>
                          <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                            <td>{{ row2.date }}</td>
                            <td>{{ row2.type }}</td>
                            <td>{{ row2.code }}</td>
                            <td>{{ row2.name }}</td>
                            <td>{{ row2.branch }}</td>
                            <td>{{ row2.value }}</td>
                            <td>{{ row2.discount }}</td>
                            <td>{{ row2.taxes }}</td>
                            <td>{{ row2.total }}</td>
                            <td>{{ row2.remaining_amount }}</td>
                          </tr>
                          <tr v-if="row.total">
                            <td colspan="5">{{ $t('total') }}</td>
                            <td>{{ row.total.total_value }}</td>
                            <td>{{ row.total.total_discounts }}</td>
                            <td>{{ row.total.total_taxes }}</td>
                            <td>{{ row.total.total_total }}</td>
                            <td>{{ row.total.total_remaining }}</td>
                          </tr>
                        </template>

                        </tbody>

                        <tfoot>
                        <tr v-if="dataTotal">
                          <td colspan="5">{{ $t('total') }}</td>
                          <th>{{ dataTotal.sum_total_value }}</th>
                          <th>{{ dataTotal.sum_total_discounts }}</th>
                          <th>{{ dataTotal.sum_total_taxes }}</th>
                          <th>{{ dataTotal.sum_total_total }}</th>
                          <th>{{ dataTotal.sum_total_remaining }}</th>
                        </tr>
                        </tfoot>
                      </table>

                    </div>

                  </div>
                </div>
              </div>

              <div class="col-12 mt-5 text-center">
                <button class="btn btn-warning" @click="loadMore">
                  <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                  {{ $t('load_more') }}
                </button>
              </div>
            </div>
            <!-- End Filter -->
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="branch">{{ $t('branch_label') }}</option>
                <option value="invoice">{{ $t('invoice') }}</option>
              </select>

            </li>
          </template>
        </b-tabs>

      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr id="myRow">
            <th>{{ $t('supplier_purchase_report.date') }}</th>
            <th>{{ $t('supplier_purchase_report.type') }}</th>
            <th>{{ $t('supplier_balance_report.code') }}</th>
            <th>{{ $t('supplier_purchase_report.supplier') }}</th>
            <th>{{ $t('supplier_purchase_report.branch') }}</th>
            <th>{{ $t('supplier_purchase_report.value') }}</th>
            <th>{{ $t('supplier_purchase_report.discounts') }}</th>
            <th>{{ $t('supplier_purchase_report.taxes') }}</th>
            <th>{{ $t('supplier_purchase_report.total_total') }}</th>
            <th>{{ $t('customer_installment_report.remaining_amount') }}</th>
          </tr>
          </thead>
          <tbody v-if="filters.group_by == 'invoice'">
          <tr v-for="(row2, index2) in dataList" :key="'yyyyy'+getRandom()+index2">
            <td>{{ row2.date }}</td>
            <td>{{ row2.type }}</td>
            <td>{{ row2.code }}</td>
            <td>{{ row2.name }}</td>
            <td>{{ row2.branch }}</td>
            <td>{{ row2.value }}</td>
            <td>{{ row2.discount }}</td>
            <td>{{ row2.taxes }}</td>
            <td>{{ row2.total }}</td>
            <td>{{ row2.remaining_amount }}</td>
          </tr>
          </tbody>
          <tbody v-else>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan="10">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.details" :key="'cfrrrrf'+getRandom()+index2+index">
              <td>{{ row2.date }}</td>
              <td>{{ row2.type }}</td>
              <td>{{ row2.code }}</td>
              <td>{{ row2.name }}</td>
              <td>{{ row2.document_number }}</td>
              <td>{{ row2.branch }}</td>
              <td>{{ row2.value }}</td>
              <td>{{ row2.discount }}</td>
              <td>{{ row2.taxes }}</td>
              <td>{{ row2.total }}</td>
              <td>{{ row2.remaining_amount }}</td>
            </tr>
            <tr v-if="row.total">
              <td colspan="5">{{ $t('total') }}</td>
              <td>{{ row.total.total_value }}</td>
              <td>{{ row.total.total_discounts }}</td>
              <td>{{ row.total.total_taxes }}</td>
              <td>{{ row.total.total_total }}</td>
              <td>{{ row.total.total_remaining }}</td>
            </tr>
          </template>

          </tbody>

          <tfoot>
          <tr v-if="dataTotalList">
            <td colspan="5">{{ $t('total') }}</td>
            <th>{{ dataTotalList.sum_total_value }}</th>
            <th>{{ dataTotalList.sum_total_discounts }}</th>
            <th>{{ dataTotalList.sum_total_taxes }}</th>
            <th>{{ dataTotalList.sum_total_total }}</th>
            <th>{{ dataTotalList.sum_total_remaining }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SuppliersPurchaseReports'"></report-main-header>
      </div>
    </div>

    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import Pie from "../components/Pie";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-supplier-purchase-report",
  components: {ReportMainHeader, 'pie-chart': Pie},

  data() {
    return {
      mainRoute: '/reports/purchases/supplier/invoices',
      mainRouteDependency: 'base/dependency',

      data: [],
      dataTotal: [],
      dataList: [],
      dataTotalList: [],
      dataChartList: null,
      suppliers: [],
      currencies: [],
      branches: [],


      data_more_details: [],
      details_total_sum: [],


      filters: {
        supplier_id: null,
        branch_id: null,
        group_by: 'supplier',
        from_date: null,
        to_date: null,
        order_by: null,
        currency_id: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      currency_name: null,
      supplier: null,
      branch: null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.filters.branch_id = val.id;
      } else {
        this.filters.branch_id = null;
      }
    },
    'filters.group_by': function () {
      this.data = [];
      this.dataTotal = [];
      this.doFilter();
    },

  },
  methods: {
    ...cssStypeForPrintReport,

    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'supplier purchase report');
      console.log(document.getElementById(tableId));
      TableToExcel.convert(document.getElementById(tableId), {
        name: this.$t('supplier_purchase_report.supplier_purchase_report') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    async getAuthUser() {
      await ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        this.currency_name = response.data.data.currency_name;
        if (this.user) {
          this.user_name = this.user.name;
        }
      });
    },

    // selectFilter() {
    //     this.getData();
    // },

    doFilter() {
      this.getData();
      // this.getDataChartList();
    },
    resetFilter() {


      this.filters.supplier_id = null;
      this.filters.branch_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.order_by = null;

      this.filters.period = null;
      this.filters.f_time_period = 0;
      this.filters.range = null;
      this.filters.financial_year = 0;
      this.supplier = null;
      this.branch = null;

      this.getData();
      // this.getDataChartList();


    },
    dataReport() {
      return this.data;
    },

    getDataChartList() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1), dataType: 'chart'}}).then((response) => {
        this.dataChartList = response.data.data;
      });
    },

    loadMore() {
      this.page = this.page + 1;

      // this.getDataChartList();
      this.getData();
    },

    getData() {
      let _limit = (this.page ? this.page : 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.total;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, export_all: 1}}).then((response) => {
        this.dataList = response.data.data.data;
        this.dataTotalList = response.data.data.total;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },

    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if (_currency) {
          this.filters.currency_id = _currency.id;
        }
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'purchases payments periodic reports');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'supplier purchase report');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('supplier_purchase_report.supplier_purchase_report'), this.$t('supplier_purchase_report.supplier_purchase_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.supplier_purchase_report")}]);

    this.getCurrencies();
    this.getAuthUser();
    this.getBranches();

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>
